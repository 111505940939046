import { RetailersBackground, InsightsLegacy, InsightsV2Beta } from "assets"
import { Image } from "components"
import { store } from "state"

export type AssetType =
  | 'loginBackground'
  | 'name'

export type ColorType =
  | 'brand'
  | 'clearOverlay'
  | 'white'
  | 'brandDark'
  | 'brandDarkHight'
  | 'border'
  | 'text'
  | 'textClear'
  | 'error'
  | 'darkOverlay'
  | 'inactive'
  | 'brandClear'
  | 'grayClear'
  | 'success'
  | 'sigmaBorder'
  | 'paused'
  | 'gray1'
  | 'suggestion'
  | 'sigmaColorsBackground'

export const getColor = (color: ColorType) => () => {
  const state = store.getState()
  const { appType } = state.main
  const common: any = {
    white: '#ffffff',
    border: '#7474745e',
    inactive: '#b8b8b8',
    text: '#1c1c1c',
    textClear: '#D6D4E0',
    error: '#A62353',
    darkOverlay: 'rgba(0, 0, 0, 0.8)',
    clearOverlay: 'rgba(255, 255, 255, .2)',
    grayClear: '#F5F5F5',
    sigmaBorder: '#d7d4e1',
    success: '#05EB97',
    gray1: '#786E82',
    paused: '#DF9525',
    suggestion: '#3C3C3C',
    sigmaColorsBackground: '#FBFAFF'
  }
  const colors: Record<string, Record<string, string>> = {
    brands_v2: {
      ...common,
      brandClear: '#c8c9db',
      brand: '#404bee',
      brandDark: '#1932a0',
      brandDarkHight: '#21184A',
    },
    brands: {
      ...common,
      brandClear: '#c8d9de',
      brand: '#1a7586',
      brandDark: '#146977',
      brandDarkHight: '#0f525c',
    },
    retailers: {
      ...common,
      brandClear: '#c8c9db',
      brand: '#404bee',
      brandDark: '#1932a0',
      brandDarkHight: '#21184A',
    },
    insights: {
      ...common,
      brandClear: '#c8c9db',
      brand: '#5c5cb4',
      brandDark: '#a55555',
      brandDarkHight: '#21184A',
    },
    legacy: {
      ...common,
      brandClear: '#c8c9db',
      brand: '#404bee',
      brandDark: '#1932a0',
      brandDarkHight: '#21184A',
    },
    legacy_insights: {
      ...common,
      brandClear: '#c8c9db',
      brand: '#404bee',
      brandDark: '#1932a0',
      brandDarkHight: '#21184A',
    }

  }
  if (appType) {
    return (colors[appType][color])
  }
  return '#ffffff'
}

// TODO: Review if this is still usefull
export const getAsset = (asset: AssetType) => () => {
  const state = store.getState()
  const { appType } = state.main
  const assets: any = {
    insights: {
      loginBackground: <Image src={RetailersBackground} alt='' />,
      name: <Image src={InsightsV2Beta} alt='Insights version 2 logo' />,
    },
    legacy: {
      loginBackground: <Image src={RetailersBackground} alt='' />,
      name: <Image src={InsightsLegacy} alt='Insights legacy logo' />,
    },
    legacy_insights: {
      loginBackground: <Image src={RetailersBackground} alt='' />,
      name: <Image src={InsightsLegacy} alt='Insights legacy logo' />,
    }
  }
  if (appType) {
    return (assets[appType][asset])
  }

}
