import styled from 'styled-components';
import { Box, BottomNavigation } from '@mui/material';
import { Link } from 'react-router-dom';
import { getColor } from '../../../utils';


const theme ={
  font: {
    family:
      "Gilroy, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    familyBold: 'GilroyBold, sans-serif',
    regular: 400,
    bold: 600,
    semiBold: 500,
    sizes: {
      xxxsmall: '1rem',
      xxsmall: '1.4rem',
      xsmall: '1.6rem',
      small: '1.8rem',
      xmedium: '2rem',
      medium: '2.2rem',
      large: '2.6rem',
      xlarge: '3.4rem',
      xxlarge: '5.2rem',
    },
  },
  border: {
    radius: '1rem',
    appRadius: '4rem',
    input: '1px solid #E2DCFD',
    rowStyled: '0.2px solid rgba(63, 81, 181, 0.2)',
    opacityRowStyled: '0.2px solid rgba(63, 81, 181, 0.9)',
  },
  boxShadow: {
    bars: '0px 2px 16px rgba(165, 85, 85, 0.18)',
    barsTop: 'inset 0px 2px 16px rgba(165, 85, 85, 0.18)',
    redp: 'inset 0px 2px 16px rgba(165, 85, 85, 0.18)',
  },
  colors: {
    default: '#f5f5fa',
    primary: '#6E51F6',
    secondary: '#F50057',
    darkPurple: '#37297B',
    texts: '#21184A',
    white: '#fff',
    bgLight: '#dce0ef',
    black: '#000',
    grey: '#786E82',
    lightGrey: '#bdbdbd',
    blueGrey: '#afb4c7',
    lilac: '#A897FA',
    purpleLight: 'rgba(85, 85, 165, 0.08)',
    redLight: 'rgba(165, 85, 85, 0.08)',
    purpleBorder: 'rgba(110, 81, 246, 0.12)',
    primaryLowOpacity: 'rgba(110, 81, 246, 0.2)',
    greyBorder: '#e9ebf6',
    lowOpacityGrey: 'rgba(255, 255, 255, 0.06)',
    lowOpacityPurple: 'rgba(113, 84, 227, 0.18)',
  },
  margins: {
    attachedHeader: '5.4rem',
  },
  spacings: {
    xxxsmall: '0.4rem',
    xxsmall: '0.8rem',
    xsmall: '1.6rem',
    small: '2.4rem',
    medium: '3.2rem',
    large: '6.0rem',
    xlarge: '6.4rem',
    xxlarge: '12.8rem',
  },
  breakpoints: {
    xxsmall: '150px',
    xsmall: '360px',
    small: '420px',
    medium: '1200px',
    large: '1550px',
    xlarge: '1900px',
    xxlarge: '2250px',
  },
}

export const Wrapper = styled(Box)`
    z-index: 99;
    position: fixed;
    box-shadow: ${theme.boxShadow.bars};
    background: ${getColor('brandDark')};
    width: 100%;
    height: 72px;
    bottom: 0;
    padding-top: 5px;
`;

export const NavigationContainer = styled(BottomNavigation)`
    height: 6rem;
    background: ${getColor('brandDark')};
    margin-bottom: ${theme.spacings.xsmall};
    &.MuiBottomNavigation-root{
      background-color: ${getColor('brandDark')};
    }
`;

export const NavigationTabLink = styled(Link)`
    min-width: 0;
    background: ${getColor('brandDark')()};
    svg {
      font-size: ${theme.font.sizes.xsmall};
      margin-bottom: ${theme.spacings.xxsmall};
      color: white;
    }
    span {
      font-size: ${theme.font.sizes.xxxsmall};
      font-family: ${theme.font.family};
      color: ${theme.colors.white};
      font-weight: ${theme.font.semiBold};
    }
    &.Mui-disabled{
      span,svg{
        color: ${getColor('inactive')};
      }
    }
    &.Mui-selected {
      color: ${theme.colors.primary};
      padding-top: 5px;
      .MuiBottomNavigationAction-label {
        font-size: ${theme.font.sizes.xxxsmall};
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 20%;
        right: 20%;
        bottom: 94%;
        background: ${theme.colors.white};
        border-radius: 6rem;
      }

      span {
        color: ${theme.colors.white};
      }

      svg {
        .first {
          fill: ${theme.colors.primary};
        }

        .second {
          fill: ${theme.colors.white};
        }

        .second-crown {
          fill: ${theme.colors.white};
        }
      }
    }

    svg {
      .first {
        fill: #a897fa;
      }

      .second-crown {
        fill: #a897fa;
      }

      .second {
        fill: #37297b;
      }
    }
`;
